<template>
  <base-form-layout
    :title="`${action} ${$tc('item.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemResourceEditForm v-if="!isLoading" :item-resource="itemResource" />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemResourceEditForm from './components/ItemResourceEditForm'
import { ITEM_RESOURCE } from '@/models/item.resource'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapActions } from 'vuex'
export default {
  name: 'ItemItemResourceEdit',
  components: {
    ItemResourceEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    itemQuotationId: {
      type: String,
      default: ''
    },
    itemResourceId: {
      type: String,
      default: ''
    },
    orderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemResource: ITEM_RESOURCE(this.itemQuotationId),
      itemQuotation: null,
      isLoading: true
    }
  },

  computed: {
    action() {
      return this.$t(this.itemResourceId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    if (this.itemResourceId) {
      await this.fetchItemResourceById()
    } else await this.createNewItemResource()
    this.isLoading = false
  },

  methods: {
    ...mapActions({
      addNotification: 'notification/add',
      fetchItemQuotation: 'itemQuotation/fetch',
      fetchItemResource: 'itemResource/fetch'
    }),
    async fetchItemResourceById() {
      const { data } = await this.fetchItemResource(this.itemResourceId)
      const { packQuantity } = data.attributes.customAttributes
      data.attributes.customAttributes.packQuantity = packQuantity || 1
      this.itemResource = data
    },

    async createNewItemResource() {
      const itemQuotation = await this.fetchItemQuotation(this.itemQuotationId)
      const {
        category,
        ncCode,
        origin,
        specie,
        type
      } = itemQuotation.data.attributes.customAttributes
      Object.assign(this.itemResource.attributes.customAttributes, {
        category,
        ncCode,
        origin,
        specie,
        type,
        incomingShipment: this.orderId
      })
    },

    async onSubmit() {
      this.beforeSubmit = false
      const action = this.itemResourceId ? 'update' : 'create'
      try {
        const { data: document } = await this.$store.dispatch(
          `itemResource/${action}`,
          this.itemResource
        )
        await this.createDocument(document.id, 'Item', 'Attachment')
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.$router.go(-1)
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
