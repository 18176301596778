<template>
  <div>
    <h6 class="text-h6 mb-5">{{ $t('common.details') }}</h6>
    <v-row dense>
      <v-col cols="12" md="4"
        ><base-selector
          v-model="itemResource.attributes.name"
          :item-text="i => $t(`inventory.categories.${i.value}`)"
          :items="inventoryCategories"
          :label="$t('common.status')"
      /></v-col>
      <v-col cols="12" md="8">
        <base-field-input
          v-model="itemResource.attributes.description"
          placeholder="W1750/1B"
          :label="$t('item.description')"
      /></v-col>

      <v-col cols="12" md="4">
        <base-field-input
          v-model="itemResource.attributes.customAttributes.quality"
          placeholder="A"
          :label="$t('item.quality')"
      /></v-col>
      <v-col cols="12" md="4">
        <base-field-input
          v-model="itemResource.attributes.customAttributes.marking"
          placeholder="LMCB0001"
          :label="$t('item.marking')"
      /></v-col>
      <v-col v-if="!itemResource.id" cols="12" md="4">
        <QuantityField filled :item="itemResource"
      /></v-col>
    </v-row>

    <h6 class="text-h6 mb-5">Dimensions</h6>
    <v-divider class="mb-8"></v-divider>
    <!-- <ItemRawProductEditFormMeasurement :item="itemResource" /> -->
    <ItemEditFormMeasurement :item="itemResource" :pack-option="true" />

    <base-textarea-input
      v-model="itemResource.attributes.customAttributes.instructions"
      :placeholder="$t('formText.addInstructionsPlaceholder')"
      :label="$t('formText.addCuttingInstructions')"
    />
    <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input />
  </div>
</template>

<script>
// import ItemRawProductEditFormMeasurement from '@/views/item-raw-product/components/ItemRawProductEditFormMeasurement'
import ItemEditFormMeasurement from '@/components/ItemEditFormMeasurement'
import QuantityField from '@/components/QuantityField'
import { INVENTORY_CATEGORIES } from '@/common/terms'
export default {
  name: 'ResourceEditForm',
  components: {
    // ItemRawProductEditFormMeasurement,
    ItemEditFormMeasurement,
    QuantityField
  },
  props: {
    itemResource: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inventoryCategories: INVENTORY_CATEGORIES
    }
  }
}
</script>
